.circle-background {
  background-color: white;
  fill: whitesmoke;
}
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #d4d4d4;
}

.circle-progress-success {
  stroke: #48ce9e;
  stroke-linecap: square;
  stroke-linejoin: inherit;
}

.progressbarSVG {
  fill: white;
}

.circle-progress-error {
  stroke: #f96a6a;
  stroke-linecap: square;
  stroke-linejoin: inherit;
}

.circle-text-success {
  font-size: 1rem;
  font-weight: bold;
  fill: #48ce9e;
}
.circle-text-error {
  font-size: 1rem;
  font-weight: bold;
  fill: #f96a6a;
}
