* {
  scrollbar-width: thin;
  scrollbar-color: black white;
}

.react-time-picker__clock {
  display: none;
}
.react-time-picker__clock--open {
  display: none;
}

.react-time-picker__wrapper {
  border-radius: 6px;
}

.oy-scroll {
  overflow-y: scroll;
}

*::-webkit-scrollbar {
  padding: 5px;
  border-radius: 15px;
  margin: 5px 5px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
  border: 3px solid white;
}
.cursor-pointer {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.solid-button {
  border: 2px solid #49eecc;
  border-radius: 2rem;
  background-color: transparent;
  color: grey;
  outline: none;
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  margin-left: 1rem;
  font-size: 0.8rem;
}

.solid-button:hover {
  border: 2px solid #49eecc;
  color: #353535;
  background-color: #49eecc;
  outline: none;
}

.LeftSidebar {
  grid-area: leftsidebar;
  border-right: 1px solid #d1d1d1;
  height: 100vh;
  width: 15vw;
  padding: 3rem 2rem;
  position: fixed;
}

.RightSidebar {
  top: 0;
  right: 0;
  width: 15vw;
  position: fixed;
  grid-area: rightsidebar;
  height: 100vh;
  border-left: 1px solid #d1d1d1;
  padding: 3rem 2rem;
}
.Main {
  grid-area: main;
  max-height: 100vh;
  padding: 3rem 2rem;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  text-decoration: inherit;
  color: inherit;
}

.dFlex {
  display: flex;
}
.react-datepicker-custom-css {
  padding: 0px 5px;
  border-radius: 5px;
  border: 0.5px solid grey;
}

.flexRow {
  flex-direction: row;
}

.flexCol {
  flex-direction: column;
}

.jcc {
  justify-content: center;
}

.jcsa {
  justify-content: space-around;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.mt5 {
  margin-top: 2rem;
}
.mr2 {
  margin-right: 1rem;
}
.avatar-img {
  border-radius: 0.3rem;
  width: 3rem;
}

.hide-scrollBar {
  -ms-overflow-style: none;
}
.hide-scrollBar::-webkit-scrollbar {
  display: none;
}

.hoverUnderline:hover {
  text-decoration: underline;
}

.example-appear {
  transform: translateY(-100%);
}

.example-appear.example-appear-active {
  transform: translateX(0%);
  transition: all 0.3s ease;
}

.example-enter {
  transform: translateX(100%);
}

.example-enter.example-enter-active {
  transform: translateX(0%);
  transition: all 0.3s ease;
}

.example-leave {
  transform: translateX(0%);
}

.example-leave.example-leave-active {
  transform: translateX(100%);
  transition: all 0.3s ease;
}

.react-confirm-alert-body {
  white-space: pre-line;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  body {
    font-size: 10px;
  }

  .jc-sm-row {
    flex-direction: column;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  body {
    font-size: 11px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  body {
    font-size: 11px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  body {
    font-size: 12px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  body {
    font-size: 12px;
  }
}
