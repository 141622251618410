.vmsApp {
  text-align: center;
  margin: 0;
  background-color: #ffffff;
  font-family: Roboto;
}
.cFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.iFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sPass {
  height: 20%;
}
.cInfo {
  height: 30%;
  width: 70%;
  word-wrap: normal;
  margin-left: auto;
  margin-right: auto;
}
.msg {
  margin-top: 50px;
  width: 80%;
  word-wrap: normal;
  margin-left: auto;
  margin-right: auto;
  /* clear:both; */
}
.interval {
  margin: 20px auto;
  justify-content: space-between;
  width: 307px;
  /* box-sizing: border-box; */
}

.myTime {
  /* width:18em; 
  margin: 20px auto 0;*/
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.myTime > label {
  position: absolute;
  width: 15em;
  text-align: left;
  top: 5%;
  left: 1%;
  z-index: 1;
  padding: 10px;
  background-color: white;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #c0c0c0;
}
.myTag {
  width: 18em;
  margin: 15px 0;
  text-align: left;
  /* padding-left: 2rem; */
}
.head {
  font-size: 14px;
  color: #c0c0c0;
}
.myTag > p {
  width: 18em;
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  color: #515151;
}
.nav-grid-item {
  color: grey;
  margin: 0px 10px;
}

.nav-grid-item:hover {
  color: black;
}
.storeLink {
  display: block;
  margin: 0 auto 14px;
}

#prevInvites {
  text-align: left;
}
/* //////////////////////////////////// */

.vms-form {
  display: inline-block;
}
.vms-input {
  /* display: block; */
  width: 307px;
  height: 44px;
  font-size: 16px;
  line-height: 19px;
  color: #515151;
  font-weight: bold;
  border: 2px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0 auto;
  padding: 10px;
}

.vms-conatct-input {
  /* display: block; */
  width: 307px;
  height: 44px;
  font-size: 16px;
  line-height: 19px;
  color: #515151;
  font-weight: bold;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 10px;
}

.vms-contact-input-wrapper {
  border: 2px solid #d9d9d9;
  border-radius: 8px;
  margin: 0px;
  padding: 0px;
}

.vms-select {
  width: 307px;
  height: 44px;
  font-size: 14.5px;
  line-height: 19px;
  color: #515151;
  font-weight: bold;
  border: 2px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
}
.vms-input::placeholder {
  color: #c0c0c0;
  font-weight: normal;
}
.vms-small {
  display: inline-block;
  text-align: left;
  color: #de6172;
}
.vms-heading {
  text-align: left;
  font-size: 16 px;
  font-weight: bold;
}

/* //////////////////////////////////////////////////////////// */

.lft {
  padding: 0;
  /* text-align: left; */
  margin-top: 5px;
  color: #7d7d7d;
  /* font-size: 25px; */
}

.log {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  white-space: nowrap;
  padding: 15px;
  margin: 15px;
}
.typeA {
  display: inline-block;
  flex: 1 0 50%;
  margin: 8px auto;
}
.typeB {
  display: inline-block;
  flex: 1 0 100%;
  margin: 8px auto;
}
.typeC {
  display: inline-block;
  flex: 1 0 50%;
  text-align: center;
  color: #e53d54;
  font-size: 18px;
  font-weight: bold;
  height: auto;
  margin: 8px auto;
  cursor: pointer;
}
.timestamp {
  text-align: right;
  font-size: 12px;
  color: #c0c0c0;
  margin: 0;
  width: 100%;
  margin-top: 10px;
}
.log .lft {
  font-weight: bold;
}
#actInvites .log {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transition: 0.3s;
}
#actInvites .log:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
#expiredInvites .log {
  box-shadow: 0px 2px 4px rgba(61, 61, 61, 0.15);
  border-radius: 8px;
}
#expiredInvites .log:hover {
  box-shadow: 0px 4px 8px rgba(61, 61, 61, 0.15);
  border-radius: 8px;
}
.footerOld {
  border-color: #ffffff;
  border-width: thin;
  border-padding: 0 px;
  /* background-color: #ffffff; */
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100px;
}
.header {
  border-color: #ffffff;
  border-width: thin;
  /* padding: 30px auto; */
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  height: 100px;
}

.bigButton {
  width: 18em;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 16px;
  border-width: 0px;
  border-radius: 20px;
  background-color: #e53d54;
  color: #ffffff;
}

.bigButton.disabled {
  cursor: default;
  background-color: #c0c0c0;
}

.bigButton:hover {
  cursor: pointer;
  background-color: #be3346;
}

.bigButton.disabled:hover {
  cursor: default;
  background-color: #c0c0c0;
}

#Details {
  margin-top: 40px;
}

#Allow {
  width: 9em;
  height: 40px;
  margin-left: auto;
  margin-right: 15px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-width: 0px;
  border-radius: 20px;
  background-color: #16b47b;
  color: #ffffff;
}

#Allow:hover {
  cursor: pointer;
  background-color: #0f7a53;
}

#Decline {
  width: 9em;
  height: 40px;
  margin-left: 15px;
  margin-right: auto;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-width: 0px;
  border-radius: 20px;
  background-color: #e53d54;
  color: #ffffff;
}

#Decline:hover {
  cursor: pointer;
  background-color: #be3346;
}

.vms-input:invalid {
  color: #ffbbc4;
}
@media (min-width: 500px) {
  .header {
    width: 50%;
    margin: 15px auto;
  }
  #decHead {
    width: 50%;
    display: inline-block;
  }
}

/* @media(min-width: 700px){
	#prevInvites{
		width: 700px;
		margin: 15px auto;
	}
} */

.vmsApp {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.nav-icon-image {
  width: 60%;
  cursor: pointer;
  transition: transform 0.2s;
}

.nav-icon-image:hover {
  transform: scale(1.1);
}
#navigation {
  display: grid;
  justify-self: center;
  text-align: center;
  max-width: 500px;
  grid-template-columns: 1fr 1fr 1fr;
}

.nav-grid-item {
  margin-bottom: 30px;
}

.nav-grid-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.headerLogo {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: "logo logout";
  width: 350px;
  margin: 20px 0px;
}

#logoutText {
  cursor: pointer;
  text-align: right;
}

#logoutText:hover {
  text-decoration: underline;
}
#brandLogo {
  grid-area: "logo";
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
}
#logoutButton {
  grid-area: "logout";
  display: flex;
  align-items: center;
  justify-content: end;
}

.mainContent {
  clear: both;
  display: inline-block;
  flex: 1 1 200px;
  max-width: 400px;
  overflow: auto;
}

.footer {
  display: flex;
  width: 100%;
  height: 100px;
  flex: 0 0 100px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.page {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pageHeading {
  display: inline;
  text-align: center;
  color: #7d7d7d;
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
  margin-bottom: 15px;
}

.strongText {
  line-height: 140%;
  font-size: 18px;
  margin: 15px auto;
  font-weight: bold;
}

.infoText {
  font-size: 14px;
  width: 70%;
}

.maxCountSelect {
  margin-bottom: 18px;
}
