/* body {
    text-align: center;
    margin: 0;
    background-color: #ffffff;
    font-family:Roboto;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
} */
.pad{
  float: left;
  margin: 0px 20px;
}
.terms{
	display:inline-block;
	clear:both;
}

.terms .lft{
	margin-top: 0px;
  width: 80%;
  height: auto;
  word-wrap: normal;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}
.storeLink{
	display:block;
	margin: 0 auto 14px;
}
/* form {
    display: inline-block;
} */
.lft{
padding:0;
text-align:center;
margin-top:5px;
color: #7D7D7D;
/* font-size: 25px; */
}
.chkform{
  text-align: center;
  width:80%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0 45px 0 0;
  /* font-size: 14px; */
}

#qr{
  margin : 20px 20px;
}

.checks{
	text-align: left;
	margin: 25px auto;
	position: relative;
	font-weight: bold;
	font-size: 14px;
	line-height: 140%;
	letter-spacing: 0.01em;


color: #515151;
}
.checks input{
  position: absolute;
  top:40%;
  right: -30px;
  visibility: hidden;
}

/* Specify the background color to be
       shown when checkbox is checked */
       .checks input:checked ~ .checkmark {
           background-color: #e53d54;
       }

/* Checkmark to be shown in checkbox */
        /* It is not be shown when not checked */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

/* Display checkmark when checked */
        .checks input:checked ~ .checkmark:after {
            display: block;
        }

.checks .checkmark:after {
            left: 3px;
            bottom: 3px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            display: block;
        }

.checks .checkmark{
 position:absolute;
 top:40%;
 right: -30px;
 width: 15px;
 height: 15px;
 border-radius: 4px;
 background-color: #c0c0c0;
}

#Details{
  display: block;
  align-content: center;
}

.dec-input{
  display: block;
  font-weight: 500;
  /* margin-left: auto;
  margin-right: auto; */
  border-width: thin;
  border-radius: 5px;
  margin: 0 10px;
  padding: 5px;
}
strong{
  font-size: 20px;
}
/* small{
  display: inline-block;
  width: 18em;
  margin:0 0 4px 10px;
  text-align: left;
  color: #ef3d53;
  visibility: hidden;
} */

.headerLogo{
  display: flex;
  float: left;
  width: 100%;
  height: 100px;
  flex: 0 0 100px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.mainContent{
  clear: both;
  display: inline-block;
  flex: 1 1 200px;
  max-width: 400px;
  overflow: auto;
}
.footer{
  display: flex;
  width: 100%;
  height: 100px;
  flex: 0 0 100px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.dec-input:invalid {
  color: #FFBBc4;
}
/* @media (min-width:500px){
  form{
  width:500px;
}
} */


.page{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pageHeading{
  display: inline;
  text-align: center;
  color: #7D7D7D;
  font-size: 25px;
  font-weight: bold;
  line-height: 35px;
  margin-bottom: 15px;
}

.strongText{
  line-height: 140%;
  font-size: 18px;
  margin: 15px auto;
  font-weight: bold;
}
.pin{
  margin: 10px;
  font-size: 52px;
  font-weight: bold;
}

.infoText{
  font-size: 14px;
  width: 70%;
}

.bigButton{
  width: 18em;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 16px;
  border-width: 0px;
  border-radius: 20px;
  background-color: #e53d54;
  color:#FFFFFF;
}

.bigButton.disabled{
  cursor: default;
  background-color: #c0c0c0;
}

.bigButton:hover{
  cursor: pointer;
  background-color: #be3346;
}

.bigButton.disabled:hover{
  cursor: default;
  background-color: #c0c0c0;
}

.declaration{
  display: inline;
  text-align: center;
  color: #7D7D7D;
  padding: 15px;
  font-size: 25px;
  font-weight:bold;
  line-height: 35px;
}

#decHead{
  width: 80%;
  display: flex;
  margin: 0 auto;
}


#OTPList {
  width: 100%;
}

li{
  width: 100%;
  font-size: 30 px;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  align-items: center;
  align-content: center;
  border: 1px solid #dfdfdf
}

li p{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.heading{
  font-weight: bold;
  
}
